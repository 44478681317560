import React from "react";
import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "~/components/layout";
import PageHeading from "~/components/styled/page-heading";
import TourList from "~/components/tour-list";

const SearchPage = ({
	data: {
		allStrapiTour: { edges },
	},
}) => {
	const flatTours = edges.map(({ node }) => node);

	//const seo = { title: "Tours" }

	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title="All Tours in India and Asia"
				description="Find you dream active adventure in India and Asia."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/tours",
				// 	},
				// 	{
				// 		hrefLang: "en-US",
				// 		href: "https://www.artofbicycletrips.com/tours",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/tours",
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: "https://www.artofbicycletrips.com/tours",
				// 	},
				// ]}
			/>
			<PageHeading>All Tours</PageHeading>
			<TourList tours={flatTours} />
		</Layout>
	);
};

export const searchPageQuery = graphql`
	query TourSearchQuery {
		allStrapiTour {
			edges {
				node {
					id
					title
					price
					duration
					slug
					featuredImage {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}

					quickfacts {
						lodging
					}

					activity {
						name
					}
					regions {
						name
						id
					}
					destination {
						name
					}
				}
			}
		}
	}
`;

export default SearchPage;
